import React, { useEffect, useState } from 'react'
import { Paper, HomeTitle, Wrapper } from './Styled'
import InputsForm from './InputsForm'
import Result from './Result'
import Step from '../mobx/Step'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

export default function HomePage() {
	const [layout, setLayout] = useState('')

	useEffect(() => {
		fetch(process.env.PUBLIC_URL + '/layouts/1.html')
			.then(r => r.text())
			.then(r => setLayout(r))
	}, [])

	return (
		<Wrapper>
			<Helmet
				// meta={[
				// 	{
				// 		name: 'description',
				// 		content: 'Наш конструктор поможем вам сделать ссылку на Viber с текстом! Бесплатный конструктор ссылок Ватсап для Инстаграм.',
				// 	},
				// ]}
				title="Сделать ссылку на Вайбер за 1 минуту"
			/>
			<HomeTitle level={1}>Сделать ссылку на Вайбер</HomeTitle>

			<StepFirst />
			<StepTwo layout={layout} />
		</Wrapper>
	)
}

const StepFirst = observer(() => {
	return Step.step === 0 ? (
		<Paper style={{ maxWidth: 500 }}>
			<InputsForm />
		</Paper>
	) : (
		<></>
	)
})

const StepTwo = observer(({ layout }: any) => {
	return Step.step === 1 ? (
		<>
			<Paper style={{ maxWidth: 500 }}>
				<Result />
			</Paper>
			<HTML dangerouslySetInnerHTML={{ __html: layout }} />
		</>
	) : (
		<></>
	)
})

const HTML = styled.div``
