import React from 'react'
import { Form, Input, Select, Button, Modal, message } from 'antd'
import Result from '../mobx/Result'
import Step from '../mobx/Step'

const { Option } = Select

const layout = {
	labelCol: { span: 10 },
}

export default function InputsForm() {
	const [form] = Form.useForm()
	const [visible, setVisible] = React.useState(false)
	console.log(Step.step)

	const handleOk = () => {
		navigator.clipboard.writeText(Result.link)
		message.success('Ссылка скопирована!')
		setVisible(false)
	}
	const handleCancel = () => {
		setVisible(false)
	}

	const onFinish = () => {
		const { prefix, phone, text } = form.getFieldsValue()
		Result.setPrefix(prefix)
		Result.setPhone(phone)
		Result.setText(text)
		// setVisible(true)
		Step.setStep(1)
	}

	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select style={{ width: 70 }}>
				<Option value="7">+7</Option>
			</Select>
		</Form.Item>
	)

	return (
		<>
			<Form
				{...layout}
				form={form}
				name="createlink"
				onFinish={onFinish}
				initialValues={{
					prefix: '7',
				}}
				scrollToFirstError
				size="large"
			>
				<Form.Item
					name="phone"
					label="Ваш номер Viber"
					style={{ display: 'block' }}
					rules={[{ required: true, message: 'Введите номер телефона!' }]}
				>
					<Input type="tel" prefix="+" style={{ width: '100%' }} />
				</Form.Item>
				{/* <Form.Item name="text" label="Текст сообщения" style={{ marginBottom: 16, display: 'block' }}>
					<Input.TextArea />
				</Form.Item> */}
				<span style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					<Button htmlType="submit" type="primary" size="large">
						Создать ссылку
					</Button>
				</span>
			</Form>
			<Modal title="Ссылка на WhatsApp" okText="Скопировать" cancelText="Закрыть" visible={visible} onOk={handleOk} onCancel={handleCancel}>
				<p>{Result.link}</p>
			</Modal>
		</>
	)
}
