import { makeAutoObservable, action } from 'mobx'
import customProtocolCheck from 'custom-protocol-check'

class AwayState {
	timer?: NodeJS.Timeout = undefined
	seconds: number = 0
	link: string = ''
	phoneLink: string = ''

	constructor() {
		makeAutoObservable(this)
	}

	startTimeout() {
		this.seconds = 0
		this.timer = setInterval(
			action(() => {
				if (this.seconds <= 0 && this.timer) {
					clearInterval(this.timer)
					this.goToLink()
					return
				}
				--this.seconds
			}),
			1000
		)
	}

	setLink(link: string) {
		this.link = link
	}

	setPhoneLink(link: string) {
		this.phoneLink = link
	}

	goToLink() {
		// const open = window.open(this.phoneLink, '_self')
		customProtocolCheck(
			this.phoneLink,
			() => {
				// console.log('Custom protocol not found.')
				alert('Похоже, вы не установили Viber!')
			},
			() => {
				// window.open(this.phoneLink, '_self')
				// console.log('Custom protocol found and opened the file successfully.')
			},
			2400
		)
		window.open(this.phoneLink, '_self')

		//window.open('https://google.com', '_self')

		// window.location.replace(this.link)
	}
}

export default new AwayState()
