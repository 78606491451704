import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import HomePage from './components/HomePage'
import Away from './components/Away'

export default function App() {
	return (
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<Switch>
				<Route path="/" exact component={HomePage} />
				<Route path="/:number" component={Away} />
			</Switch>
		</BrowserRouter>
	)
}
